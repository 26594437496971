export const employerViewFedExLabel = $.__('Does federal tax withholding apply to this employee?');
export const employeeViewFedExLabel = $.__('Does federal tax withholding apply to you?');

export const employerViewFedExNoPhrase = $.__('No, employee is federal tax exempt (This is uncommon)');
export const employeeViewFedExNoPhrase = $.__('No, I am federal tax exempt');

export const employerFedExNoMessageHeader = $.__('Federal Income Tax and Any Additional Withholdings Won’t Be Deducted From This Employee’s Paychecks');
export const employeeFedExNoMessageHeader = $.__('Federal Income Tax Won’t Be Withheld From Your Paychecks');

export const employeeFedExFICAMessageHeader = $.__('You Are Exempt From FICA');
export const employeeFedExFICAMessageDesc = $.__('Social Security and Medicare taxes won’t be collected from your paychecks or reported on your W-2.');

export const fedExFICAErrorHeader = $.__('Uh oh, something went wrong with your FICA or FUTA');
export const fedExFICAErrorDesc = $.__('Please refresh your page and try again.');

export const employerFedExFUTAMessageHeader = $.__('Federal Unemployment Taxes Won’t Be Withheld From This Employee’s Paycheck');
export const employerFedExFICAMessageHeader = $.__('Social Security, and Medicare Taxes Won’t Be Withheld From This Employee’s Paycheck');
export const employerFedExFICAandFUTAMessageHeader = $.__('Federal Unemployment, Social Security, and Medicare Taxes Won’t Be Withheld From This Employee’s Paycheck');

export const employerFedExFUTAMessageDesc = $.__('These taxes won’t be collected and income won’t be reported on their W-2.');
export const employerFedExFICAMessageDesc = $.__('These taxes won’t be collected and income won’t be reported on their W-2.');
export const employerFedExFICAandFUTAMessageDesc = $.__('These taxes won’t be collected and income won’t be reported on their W-2.');

export function futaOrFicaPhrasing(isFutaExempt: boolean, isFicaExempt: boolean, isEmployerView: boolean) {
    if (isEmployerView) {
        if (isFutaExempt && !isFicaExempt) {
            return {
                header: employerFedExFUTAMessageHeader,
                body: employerFedExFUTAMessageDesc
            };
        }
        if (!isFutaExempt && isFicaExempt) {
            return {
                header: employerFedExFICAMessageHeader,
                body: employerFedExFICAMessageDesc
            };
        }
        return {
            header: employerFedExFICAandFUTAMessageHeader,
            body: employerFedExFICAandFUTAMessageDesc
        };
    }

    if (isFicaExempt) {
        return {
            header: employeeFedExFICAMessageHeader,
            body: employeeFedExFICAMessageDesc
        };
    }

    return {
        header: fedExFICAErrorHeader,
        body: fedExFICAErrorDesc
    };
}
