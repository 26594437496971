import Select from 'select.react';
import { BodyText, CurrencyToggle, Flex, FormNote, IconButton, Label, LayoutBox, StyledBox } from '@bamboohr/fabric';
import { FloatingIconButton } from '@fabric/button';
import { classNameFromObject } from '@utils/dom';

import './account-card.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function AccountCard(props) {
	const {
		accountId,
		accountNum,
		accountType,
		amount,
		bankName,
		flatOrPercent,
		hasAccountNumberError,
		hasAccountTypeError,
		hasAmountError,
		hasRoutingNumberError,
		imgUrl,
		isProcessing,
		onDeleteAccount,
		onRoutingNumBlur,
		onValueChange,
		order,
		routingNum,
		routingNumError,
		routingNumPending,
		showDelete,
	} = props;

	const routingNumInputClasses = ifFeature(
		'encore',
		classNameFromObject(
			'fab-TextInput fab-TextInput--small fab-TextInput--width4',
			{
				'fab-TextInput--error': hasRoutingNumberError,
			},
		),
		classNameFromObject('fab-TextInput fab-TextInput--width4', {
			'fab-TextInput--error': hasRoutingNumberError,
		}),
	);

	return (
		ifFeature('encore',
		<div className="AccountCard">
			<h4 className="AccountCard__orderNumberHeader">{ $.__('Account %1:', order) }</h4>
			<StyledBox backgroundColor="neutral-extra-strong" borderRadius="medium" padding={2} marginBottom={1}>
				<Flex flexDirection="row">
					<LayoutBox marginRight={1} marginTop={1}>
						<div className="AccountCard__orderNumber">
							<StyledBox borderRadius="large" height="100%" width="100%">
								<Flex alignItems="center" height="100%" justifyContent="center" justifyItems="center">
									<BodyText size="small" weight="bold">
										{ order }
									</BodyText>
								</Flex>
							</StyledBox>
						</div>
					</LayoutBox>

					<Flex flex="1" flexDirection="column" gap={1}>
						<Flex alignItems="center" flexDirection="row" gap={1}>
							<label className="fab-Label hidden phoneOnly phoneOnly--flex depositLabel" htmlFor={ `amountType${ accountId }` }>{ $.__('Deposit') }</label>
							<span className="fab-FormField phoneHide">{ $.__('Deposit') }</span>
							<LayoutBox>
								<CurrencyToggle
									id={ `amountType${ accountId }` } 
									isChecked={flatOrPercent === 'FLAT'}
									isDisabled={isProcessing} 
									name={ `amountType${ accountId }` }
									onChange={ (event, customEvent) => { onValueChange(customEvent, accountId, 'flatOrPercent', true) }} 
									size="small"
								/>
							</LayoutBox>
							<LayoutBox>
								<input
									className={`fab-TextInput fab-TextInput--small fab-TextInput--width3 js-depositAmount${accountId}${
										hasAmountError ? ' fab-TextInput--error' : ''
									}`}
									data-cursymbol="$"
									data-decimal-char="."
									data-thousands-separator=","
									disabled={isProcessing}
									onBlur={ event => onValueChange(event, accountId, 'amount', true) }
									onChange={ event => onValueChange(event, accountId, 'amount') }
									type="text"
									value={ amount }
								/>
							</LayoutBox>
							<BodyText>
								{ order > 1 ? $.__('of the remaining amount into') : $.__('of each check into') }:
							</BodyText>
							{showDelete && (
								<Flex flex="1" justifyContent="flex-end">
									<div className="AccountCard__delete">
										<IconButton
											disabled={isProcessing}
											icon='trash-can-regular'
											onClick={() => onDeleteAccount(accountId)}
											type='button'
											variant="outlined"
										/>
									</div>
								</Flex>
							)}
						</Flex>

						<Flex alignItems="center" flexDirection="row" gap={1}>
							<LayoutBox>
								<Label
									htmlFor="accountNum"
									status={hasAccountNumberError ? 'error' : undefined}
								>
									{$.__('Account Number')}
								</Label>
								<input
									className={`fab-TextInput fab-TextInput--small fab-TextInput--width4${
										hasAccountNumberError ? ' fab-TextInput--error' : ''
									}`}
									disabled={isProcessing}
									id="accountNum"
									onChange={ event => onValueChange(event, accountId, 'accountNum') }
									type="text"
									value={ accountNum }
								/>
							</LayoutBox>
							<LayoutBox>
								<Label
									htmlFor="routingNum"
									status={hasRoutingNumberError ? 'error' : undefined}
								>
									{$.__('Routing Number')}
								</Label>
								<input
									className={ routingNumInputClasses }
									disabled={isProcessing}
									id="routingNum"
									onBlur={ event => onRoutingNumBlur(event.target.value, accountId) }
									onChange={ event => onValueChange(event, accountId, 'routingNum') }
									type="text"
									value={ routingNum }
								/>
							</LayoutBox>
							<LayoutBox>
								<Label
									htmlFor="accountType"
									status={hasAccountTypeError ? 'error' : undefined}
								>
									{$.__('Account Type')}
								</Label>
								<Select
									condition={hasAccountTypeError ? 'error' : undefined}
									data={ {items: getSelectOptions(accountType)} }
									id="accountType"
									isDisabled={isProcessing}
									onChange={ event => onValueChange(event, accountId, 'accountType') }
									settings={ {notClearable: true} }
									size="small"
									width="4"
								/>
							</LayoutBox>
							<LayoutBox marginTop={3}>
								{ renderValidationContent(routingNumPending, bankName, imgUrl, routingNumError) }
							</LayoutBox>
						</Flex>
					</Flex>
				</Flex>
			</StyledBox>
		</div>,
		<div className="AccountCard">
			<h4 className="AccountCard__orderNumberJadeHeader">{ $.__('Account %1:', order) }</h4>
			<div className="AccountCard__slat">
				<div className="AccountCard__order">
					<div className="AccountCard__orderNumberJade">
						{ order }
					</div>
				</div>

				<div className="AccountCard__form">
					<div className="fab-FormRow">
						<label className="fab-Label hidden phoneOnly phoneOnly--flex depositLabel" htmlFor={ `amountFlat${ accountId }` }>{ $.__('Deposit') }</label>
						<span className="fab-FormField phoneHide">{ $.__('Deposit') }</span>
						<div className="fab-FormField">
							<div className="bhrToggleSmall bhrToggle--btn">
								<input
									className="bhrToggle--btn-1"
									data-currency-toggle="$"
									data-field-class={ `js-depositAmount${ accountId }` }
									data-flag="currency"
									defaultChecked={ flatOrPercent === 'FLAT' ? 'checked' : null }
									disabled={isProcessing}
									id={ `amountFlat${ accountId }` }
									name={ `amountType${ accountId }` }
									onChange={ event => onValueChange(event, accountId, 'flatOrPercent', true) }
									type="radio"
									value="FLAT"
								/>
								<label className="bhrToggle__label bhrToggle--btn__label" htmlFor={ `amountFlat${ accountId }` }>$</label>
								<input
									className="bhrToggle--btn-2"
									data-currency-toggle="%"
									data-field-class={ `js-depositAmount${ accountId }` }
									data-flag="percent"
									defaultChecked={ flatOrPercent === 'PCT' ? 'checked' : null }
									disabled={isProcessing}
									id={ `amountPercent${ accountId }` }
									name={ `amountType${ accountId }` }
									onChange={ event => onValueChange(event, accountId, 'flatOrPercent', true) }
									type="radio"
									value="PCT"
								/>
								<label className="bhrToggle__label bhrToggle--btn__label" htmlFor={ `amountPercent${ accountId }` }>%</label>
								<span className="bhrToggle__handle bhrToggle--btn__handle brandedToggle"></span>
							</div>
						</div>
						<div className="fab-FormField">
							<input
								className={`fab-TextInput fab-TextInput--width3 js-depositAmount${accountId} ${
									flatOrPercent === 'PCT' ? 'percent-field' : 'currency-field'
								}${
									hasAmountError ? ' fab-TextInput--error' : ''
								}`}
								data-cursymbol="$"
								data-decimal-char="."
								data-thousands-separator=","
								disabled={isProcessing}
								onBlur={ event => onValueChange(event, accountId, 'amount', true) }
								onChange={ event => onValueChange(event, accountId, 'amount') }
								type="text"
								value={ amount }
							/>
						</div>
						<span className="fab-FormField DepositNote">
							{ order > 1 ? $.__('of the remaining amount into') : $.__('of each check into') }:
						</span>
					</div>

					<div className="fab-FormRow">
						<div className="fab-FormColumn">
							<label
								className={`fab-Label${
									hasAccountNumberError ? ' fab-Label--error' : ''
								}`}
								htmlFor="accountNum"
							>
								{$.__('Account Number')}
							</label>
							<input
								className={`fab-TextInput fab-TextInput--width4${
									hasAccountNumberError ? ' fab-TextInput--error' : ''
								}`}
								disabled={isProcessing}
								id="accountNum"
								onChange={ event => onValueChange(event, accountId, 'accountNum') }
								type="text"
								value={ accountNum }
							/>
						</div>
						<div className="fab-FormColumn">
							<label
								className={`fab-Label${
									hasRoutingNumberError ? ' fab-Label--error' : ''
								}`}
								htmlFor="routingNum"
							>
								{$.__('Routing Number')}
							</label>
							<input
								className={ routingNumInputClasses }
								disabled={isProcessing}
								id="routingNum"
								onBlur={ event => onRoutingNumBlur(event.target.value, accountId) }
								onChange={ event => onValueChange(event, accountId, 'routingNum') }
								type="text"
								value={ routingNum }
							/>
						</div>
						<div className="fab-FormColumn lastColumn">
							<label
								className={`fab-Label${
									hasAccountTypeError ? ' fab-Label--error' : ''
								}`}
								htmlFor="accountType"
							>
								{$.__('Account Type')}
							</label>
							<Select
								condition={hasAccountTypeError ? 'error' : undefined}
								data={ {items: getSelectOptions(accountType)} }
								id="accountType"
								isDisabled={isProcessing}
								onChange={ event => onValueChange(event, accountId, 'accountType') }
								settings={ {notClearable: true} }
								width="5"
							/>
						</div>
						<div className="AccountCard__validation">
							{ renderValidationContent(routingNumPending, bankName, imgUrl, routingNumError) }
						</div>
					</div>
				</div>

				{ showDelete && (
					<div className="AccountCard__delete">
						<FloatingIconButton clickAction={ () => onDeleteAccount(accountId) } icon="fab-trash-can-10x12" isDisabled={isProcessing} secondary={ true } type="button" />
					</div>
				) }
			</div>
		</div>)
	);

}

function getSelectOptions(currentType) {
	return [
		{
			displayText: $.__('Checking'),
			value: 'Checking',
			selected: currentType === 'Checking'
		},
		{
			displayText: $.__('Savings'),
			value: 'Savings',
			selected: currentType === 'Savings'
		}
	];
}

function renderValidationContent(pending, name, url, error) {
	if (pending) {
		return (
			<div className="AccountCard__bankPending">
				<div className="dotLoader small">
					<div className="bounce1"></div>
					<div className="bounce2"></div>
					<div className="bounce3"></div>
				</div>
				<span>{ $.__('Validating') }...</span>
			</div>
		);
	}

	if (error) {
		const classes = 'fab-FormNote fab-FormNote--error';
		return (
			ifFeature('encore',
				<FormNote error={true}>
					{ $.__(`Sorry, that's not a valid Routing Number`) }
				</FormNote>,
				<span className={ classes }>
					{ $.__(`Sorry, that's not a valid Routing Number`) }
				</span>
			)
		);
	}

	if (url) {
		return <img alt={ name ? `${ name } logo` : 'bank logo' } className="AccountCard__bankLogo" src={ url } />;
	}

	if (name) {
		return <span className="AccountCard__bankText">{ name }</span>;
	}

	return null;
}
