import { BodyText, IconButton, IconV2, LayoutBox, Popover } from '@bamboohr/fabric';
import React, { useRef, useState } from 'react';

export function FedWithholdingExemptionNote() {
	const buttonRef = useRef(null);

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	return (
		<div>
			<IconButton
				aria-label={$.__('FedWithholdingExemptionNote')}
				color="primary"
				floatingIcon={true}
				icon={<IconV2 name="circle-question-regular" size={12} />}
				noBoundingBox={true}
				onClick={() => setIsPopoverOpen(true)}
				ref={buttonRef}
				size="teenie"
				type="button"
			/>
			<Popover
				anchorEl={buttonRef.current}
				onClose={() => setIsPopoverOpen(false)}
				open={isPopoverOpen}
				placement="right"
				title={$.__(`Exemption Options`)}
			>
				<BodyText>
					{$.__('FUTA provides federal unemployment compensation to workers who lose their jobs, while FICA funds Social Security and Medicare.')}
				</BodyText>
				<LayoutBox marginTop={1}>
					{$.__('If an employee is exempt from FUTA or FICA, these taxes won’t be collected, and income won’t be reported on their W-2.')}
				</LayoutBox>
			</Popover>
		</div>
	);
}
