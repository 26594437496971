import {
	Checkbox,
	Flex,
	InlineMessage,
	Label,
	LayoutBox,
	Radio,
	Select,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
import { isEnabled } from 'FeatureToggle.util';

/* @endCleanup encore */
import { Message, ExternalLink } from '@utils/message';
import {
	Fragment,
	useState,
} from 'react';
import c from 'classnames';

/* @startCleanup encore */
import { Select as OldSelect } from '@fabric/select';
/* @endCleanup encore */

import { AdditionalWithholding } from '../additional-withholding.react';
import { HelpPopover } from '../help-popover.react';
import { SectionStyledBox } from '../section-styled-box';
import { SectionStyledHeader } from '../section-styled-header';
import {
	employerViewFedExLabel,
	employeeViewFedExLabel,
	employerViewFedExNoPhrase,
	employeeViewFedExNoPhrase,
	employerFedExNoMessageHeader,
	employeeFedExNoMessageHeader,
	futaOrFicaPhrasing,
} from './fed-withholding-2020.domain';
import { FedWithholdingExemptionNote } from './fed-withholding-exemption-note';
import { FedWithholdingEmployeeExemptionNote } from './fed-withholding-employee-exemption-note';

import './fed-withholding-2020.styl';

const fedWithholding2020SelectItems = [
	{
		text: $.__('Single, or Married Filing Separately'),
		value: 'Single',
	},
	{
		text: $.__('Married Filing Jointly'),
		value: 'Married',
	},
	{
		text: $.__('Head of Household'),
		value: 'marriedSingleRate',
	},
];

export function FedWithholding2020(props) {
	const {
		filingStatus: selectedFilingStatus,
		twoJobs = false,
		dependentsAmount = '',
		headerHeadlineSize,
		otherIncome = '',
		deductions = '',
		viewOnly = false,
		additionalWithholding = {
			amount: 0,
			type: 'currency',
		},
		ignoreStatusExemptions: selectedIgnoreStatus = false,
		isEmployerView,
		futaExempt = false,
		ficaExempt = false,
		showExemptions = true,
		showWithholding,
		usesSectionComponent,
	} = props;
	const {
		amount,
		type,
	} = additionalWithholding;

	const [filingStatus, setFilingStatus] = useState(selectedFilingStatus);
	const [isFicaExempt, setIsFicaExempt] = useState(ficaExempt);
	const [isFutaExempt, setIsFutaExempt] = useState(futaExempt);
	const [ignoreStatus, setIgnoreStatus] = useState(selectedIgnoreStatus);
	const federalExemptViewAccess = isEnabled('PAYROLL_FED_TAX_EXEMPT_UPDATES');

	const employerFedExNoMessageDesc = 
		<Flex flexDirection="column">
			<Flex marginBottom={1}>{$.__('Just so you know, federal taxable wages will still be reported as income on their W-2.')}</Flex>
			<div>
				<Message
					// eslint-disable-next-line new-cap
					link={ ExternalLink('https://www.irs.gov/pub/irs-pdf/fw4.pdf') }
					text={ $._('**Please note:** The IRS requires annual updates to employees’ [W-4] form when they are exempt.') }
				/>
			</div>
		</Flex>;
	const employeeFedExNoMessageDesc =
		<Flex flexDirection="column">
			<Flex marginBottom={1}>{$.__('Just so you know, federal taxable wages will still be reported as income on your W-2.')}</Flex>
			<div>
				<Message
					// eslint-disable-next-line new-cap
					link={ ExternalLink('https://www.irs.gov/pub/irs-pdf/fw4.pdf') }
					text={ $._('**Please note:** The IRS requires annual updates to your [W-4] form when you are exempt.') }
				/>
			</div>
		</Flex>;

	const filingStatusLabelClasses = c(
		'fab-Label fab-Label--required',
		{ 'fab-Label--disabled': ignoreStatus || viewOnly }
	);
	const otherLabelClasses = c(
		'fab-Label',
		{ 'fab-Label--disabled': viewOnly }
	);
	/* @startCleanup encore */
	const fieldsetClasses = c(
		'FedWithholding2020',
		'fab-FormSection',
		{ 'fab-FormSection--hidden': showWithholding === false },
	);
	/* @endCleanup encore */

	return ifFeature('encore',
		(
			<SectionStyledBox
				hidden={ showWithholding === false }
				usesSectionComponent={ usesSectionComponent }
			>
				<fieldset>
					<SectionStyledHeader
						hasPopover={ true }
						headlineSize={ headerHeadlineSize }
						popoverContent={ (
							<Message
								// eslint-disable-next-line new-cap
								link={ ExternalLink('https://apps.irs.gov/app/withholdingcalculator/') }
								text={ $._('This determines how much you will withhold from your paycheck for federal taxes. The IRS has provided a handy [withholding calculator] to help you figure out what to elect.') }
							/>
						) }
						popoverIcon="circle-question-regular"
						popoverPlacement="bottom"
						popoverTitle={ $.__('Federal Tax Withholding') }
					>
						{ $.__('Federal Tax Withholding') }
					</SectionStyledHeader>

					{ federalExemptViewAccess ?
						<Flex alignItems="center" width="100%">
							<Flex flexDirection="column">
								<Flex marginTop={ 2 }>
									<Label disabled={ viewOnly }>{ isEmployerView ? employerViewFedExLabel : employeeViewFedExLabel }</Label>
									{ federalExemptViewAccess === true && isEmployerView === false && viewOnly ? 
										<LayoutBox marginBottom={1} marginLeft={1}>
											<FedWithholdingEmployeeExemptionNote />
										</LayoutBox> 
									: null }
								</Flex>
								<Flex marginTop={ 1 }>
									<LayoutBox marginRight={ 1 }>
										<Radio
											checked={ ignoreStatus === false }
											disabled={ viewOnly }
											label={ $.__('Yes') }
											onChange={ () => {
												setIgnoreStatus(false);
											} }
											size="small"
											value={ !ignoreStatus }
										/>
									</LayoutBox>
									<Radio
										checked={ ignoreStatus === true }
										disabled={ viewOnly }
										label={ isEmployerView ? employerViewFedExNoPhrase : employeeViewFedExNoPhrase }
										name="fed[taxExempt]"
										onChange={ () => {
											setIgnoreStatus(true);
										} }
										size="small"
										value={ ignoreStatus ? 'on' : 'off' }
									/>
								</Flex>
							</Flex>
						</Flex>
						: null }

					{ federalExemptViewAccess === false || (federalExemptViewAccess === true && ignoreStatus === false) ?
						<>
							<LayoutBox marginTop={federalExemptViewAccess === true ? 0 : 2}>
								<label className={filingStatusLabelClasses} htmlFor="filingStatus">{$.__('Filing Status')}</label>
							</LayoutBox><div className="fab-FormRow">
							<div className="fab-FormColumn">
								<Select
									isDisabled={ignoreStatus || viewOnly}
									items={fedWithholding2020SelectItems}
									name="fed[status2020]"
									onChange={(selectedValues) => {
										const [value = ''] = selectedValues;
										setFilingStatus(value);
									} }
									onClear={() => {
										setFilingStatus('');
									} }
									selectedValues={[filingStatus]}
									width={8} />
							</div>
							{showExemptions && federalExemptViewAccess === false && (
								<div className="fab-Checkbox fab-FormColumn">
									<input
										className="fab-Checkbox__input"
										defaultChecked={ignoreStatus ? 'checked' : null}
										disabled={viewOnly ? 'disabled' : null}
										id="ignoreStatus"
										name="fed[taxExempt]"
										onChange={event => setIgnoreStatus(event.target.checked)}
										type="checkbox" />
									<label className="fab-Checkbox__label" htmlFor="ignoreStatus">{$.__('Ignore filing status/other adjustments')}</label>
								</div>
							)}
						</div><div className="fab-FormRow">
							<div className="fab-Checkbox">
								<input
									className="fab-Checkbox__input"
									defaultChecked={twoJobs}
									disabled={viewOnly ? 'disabled' : null}
									id="twoJobs"
									name="fed[twoJobs]"
									type="checkbox" />
								<label className="fab-Checkbox__label" htmlFor="twoJobs">{$.__('Works two jobs or spouse works?')}</label>
							</div>
						</div><div className="fab-FormRow">
							<div className="fab-FormColumn">
								<label className={otherLabelClasses} htmlFor="dependentsAmt">{$.__('Dependents Amount')}</label>
								<input
									className="fab-TextInput fab-TextInput--width5 currency-field"
									data-cursymbol="$"
									data-decimal-char="."
									data-thousands-separator=","
									defaultValue={dependentsAmount}
									disabled={viewOnly ? 'disabled' : null}
									id="dependentsAmt"
									name="fed[dependentsAmt]"
									type="text" />
							</div>
							<div className="fab-FormColumn">
								<label className={otherLabelClasses} htmlFor="otherIncome">{$.__('Other Income')}</label>
								<input
									className="fab-TextInput fab-TextInput--width5 currency-field"
									data-cursymbol="$"
									data-decimal-char="."
									data-thousands-separator=","
									defaultValue={otherIncome}
									disabled={viewOnly ? 'disabled' : null}
									id="otherIncome"
									name="fed[otherIncome]"
									type="text" />
							</div>
							<div className="fab-FormColumn">
								<label className={otherLabelClasses} htmlFor="deductionsAmt">{$.__('Deductions Amount')}</label>
								<input
									className="fab-TextInput fab-TextInput--width5 currency-field"
									data-cursymbol="$"
									data-decimal-char="."
									data-thousands-separator=","
									defaultValue={deductions}
									disabled={viewOnly ? 'disabled' : null}
									id="deductionsAmt"
									name="fed[deductionsAmt]"
									type="text" />
							</div>
						</div><AdditionalWithholding
							data={{
								amount,
								type
							}}
							is2020={true}
							type="fed"
							viewOnly={viewOnly} />
						</>
					: 
					<Flex flex={1} flexDirection='column' marginBottom={2} marginTop={1}>
						<InlineMessage description={isEmployerView ? employerFedExNoMessageDesc : employeeFedExNoMessageDesc} icon={false} title={isEmployerView ? employerFedExNoMessageHeader : employeeFedExNoMessageHeader} />
					</Flex> }
					
					{ showExemptions && (
						<Fragment>
							<Flex>
								<Label>
									{ $.__('Exemption Options') }
								</Label>
								{ federalExemptViewAccess === true ? 
									<LayoutBox marginBottom={1} marginLeft={1}>
										<FedWithholdingExemptionNote />
									</LayoutBox> 
								: null }
							</Flex>
							<div className="fab-FormRow">
								<Checkbox
									checked={isFutaExempt}
									disabled={viewOnly}
									id="futaExempt"
									label={$.__('FUTA exempt')}
									name="fed[futaExempt]"
									onChange={() => setIsFutaExempt(!isFutaExempt)}
									value={isFutaExempt}
								/>
								<LayoutBox marginLeft={1}>
									<Checkbox
										checked={isFicaExempt}
										disabled={viewOnly}
										id="ficaExempt"
										label={$.__('FICA exempt')}
										name="fed[ficaExempt]"
										onChange={() => setIsFicaExempt(!isFicaExempt)}
										value={isFicaExempt}
									/>
								</LayoutBox>
							</div>
						</Fragment>
					) }
				{ federalExemptViewAccess && ((isEmployerView === true && (isFicaExempt || isFutaExempt)) || (isEmployerView === false && isFicaExempt)) ? 
						<Flex flex={1} flexDirection='column' marginBottom={2} marginTop={1}>
							<InlineMessage description={futaOrFicaPhrasing(isFutaExempt, isFicaExempt, isEmployerView).body} icon={false} title={futaOrFicaPhrasing(isFutaExempt, isFicaExempt, isEmployerView).header} />
						</Flex>
					: null }
				</fieldset>
			</SectionStyledBox>
		),
		(
			<fieldset className={ fieldsetClasses }>
				<legend className="fab-FormSection__legend">
					{ $.__('Federal Tax Withholding') }
					<HelpPopover { ...getHelpPopoverProps() } />
				</legend>
				
				{ federalExemptViewAccess ?
					<Flex alignItems="center" width="100%" marginBottom={ 1 }>
						<Flex flexDirection="column">
							<Flex>
								<Label disabled={ viewOnly }>{ isEmployerView ? employerViewFedExLabel : employeeViewFedExLabel }</Label>
								{ federalExemptViewAccess === true && isEmployerView === false && viewOnly ? 
									<LayoutBox marginLeft={ 1 }>
										<FedWithholdingEmployeeExemptionNote />
									</LayoutBox> 
								: null }
							</Flex>
							<Flex>
								<LayoutBox marginRight={ 1 }>
									<Radio
										checked={ ignoreStatus === false }
										disabled={ viewOnly }
										label={ $.__('Yes') }
										onChange={ () => {
											setIgnoreStatus(false);
										} }
										size="small"
										value={ !ignoreStatus }
									/>
								</LayoutBox>
								<Radio
									checked={ ignoreStatus === true }
									disabled={ viewOnly }
									label={ isEmployerView ? employerViewFedExNoPhrase : employeeViewFedExNoPhrase }
									name="fed[taxExempt]"
									onChange={ () => {
										setIgnoreStatus(true);
									} }
									size="small"
									value={ ignoreStatus ? 'on' : 'off' }
								/>
							</Flex>
						</Flex>
					</Flex>
					: null }
					
				{ federalExemptViewAccess === false || (federalExemptViewAccess === true && ignoreStatus === false) ?
					<>
						<label className={ filingStatusLabelClasses } htmlFor="filingStatus">{ $.__('Filing Status') }</label>
						<div className="fab-FormRow">
							<div className="fab-FormColumn">
								<OldSelect { ...getFilingStatusProps(filingStatus, setFilingStatus, ignoreStatus, viewOnly) } />
							</div>
							{showExemptions && federalExemptViewAccess === false && (
								<div className="fab-Checkbox fab-FormColumn">
									<input
										className="fab-Checkbox__input"
										defaultChecked={ ignoreStatus ? 'checked' : null }
										disabled={ viewOnly ? 'disabled' : null }
										id="ignoreStatus"
										name="fed[taxExempt]"
										onChange={ event => setIgnoreStatus(event.target.checked) }
										type="checkbox"
									/>
									<label className="fab-Checkbox__label" htmlFor="ignoreStatus">{ $.__('Ignore filing status/other adjustments') }</label>
								</div>
							) }
						</div>
						<div className="fab-FormRow">
							<div className="fab-Checkbox">
								<input
									className="fab-Checkbox__input"
									defaultChecked={ twoJobs }
									disabled={ viewOnly ? 'disabled' : null }
									id="twoJobs"
									name="fed[twoJobs]"
									type="checkbox"
								/>
								<label className="fab-Checkbox__label" htmlFor="twoJobs">{ $.__('Works two jobs or spouse works?') }</label>
							</div>
						</div>
						<div className="fab-FormRow">
							<div className="fab-FormColumn">
								<label className={ otherLabelClasses } htmlFor="dependentsAmt">{ $.__('Dependents Amount') }</label>
								<input
									className="fab-TextInput fab-TextInput--width5 currency-field"
									data-cursymbol="$"
									data-decimal-char="."
									data-thousands-separator=","
									defaultValue={ dependentsAmount }
									disabled={ viewOnly ? 'disabled' : null }
									id="dependentsAmt"
									name="fed[dependentsAmt]"
									type="text"
								/>
							</div>
							<div className="fab-FormColumn">
								<label className={ otherLabelClasses } htmlFor="otherIncome">{ $.__('Other Income') }</label>
								<input
									className="fab-TextInput fab-TextInput--width5 currency-field"
									data-cursymbol="$"
									data-decimal-char="."
									data-thousands-separator=","
									defaultValue={ otherIncome }
									disabled={ viewOnly ? 'disabled' : null }
									id="otherIncome"
									name="fed[otherIncome]"
									type="text"
								/>
							</div>
							<div className="fab-FormColumn">
								<label className={ otherLabelClasses } htmlFor="deductionsAmt">{ $.__('Deductions Amount') }</label>
								<input
									className="fab-TextInput fab-TextInput--width5 currency-field"
									data-cursymbol="$"
									data-decimal-char="."
									data-thousands-separator=","
									defaultValue={ deductions }
									disabled={ viewOnly ? 'disabled' : null }
									id="deductionsAmt"
									name="fed[deductionsAmt]"
									type="text"
								/>
							</div>
						</div>
						<AdditionalWithholding
							data={ {
								amount,
								type
							} }
							is2020={ true }
							type="fed"
							viewOnly={ viewOnly }
						/>
					</>
					: <Flex flex={1} flexDirection='column' marginBottom={2} marginTop={1}>
						<InlineMessage description={isEmployerView ? employerFedExNoMessageDesc : employeeFedExNoMessageDesc} icon={false} title={isEmployerView ? employerFedExNoMessageHeader : employeeFedExNoMessageHeader} />
					</Flex> }

				{ showExemptions && (
					<Fragment>
						<Flex>
							<Label>
								{ $.__('Exemption Options') }
							</Label>
							{ federalExemptViewAccess === true ? 
								<LayoutBox marginLeft={1}>
									<FedWithholdingExemptionNote />
								</LayoutBox> 
							: null }
						</Flex>
						<div className="fab-FormRow">
							<Checkbox
								checked={isFutaExempt}
								disabled={viewOnly}
								id="futaExempt"
								label={$.__('FUTA exempt')}
								name="fed[futaExempt]"
								onChange={() => setIsFutaExempt(!isFutaExempt)}
								value={isFutaExempt}
							/>
							<LayoutBox marginLeft={1}>
								<Checkbox
									checked={isFicaExempt}
									disabled={viewOnly}
									id="ficaExempt"
									label={$.__('FICA exempt')}
									name="fed[ficaExempt]"
									onChange={() => setIsFicaExempt(!isFicaExempt)}
									value={isFicaExempt}
								/>
							</LayoutBox>
						</div>
					</Fragment>
				) }
				{ federalExemptViewAccess && (isEmployerView === true || (isEmployerView === false && isFicaExempt)) ? 
					<Flex flex={1} flexDirection='column' marginBottom={2} marginTop={1}>
						<InlineMessage description={futaOrFicaPhrasing(isFutaExempt, isFicaExempt, isEmployerView).body} icon={false} title={futaOrFicaPhrasing(isFutaExempt, isFicaExempt, isEmployerView).header} />
					</Flex>
				: null }
			</fieldset>
		)
	);
}

/* @startCleanup encore */
function getFilingStatusProps(filingStatus, setFilingStatus, ignoreStatus, viewOnly) {
	return {
		isDisabled: ignoreStatus || viewOnly,
		items: [
			{
				text: $.__('Single, or Married Filing Separately'),
				value: 'Single',
			},
			{
				text: $.__('Married Filing Jointly'),
				value: 'Married',
			},
			{
				text: $.__('Head of Household'),
				value: 'marriedSingleRate',
			},
		],
		name: 'fed[status2020]',
		onClear: () => setFilingStatus(''),
		onSelect: value => setFilingStatus(value || ''),
		selectedValues: [filingStatus],
		width: '8',
	};
}

function getHelpPopoverProps() {
	const contentFirst = $.__('This determines how much you will withhold from your paycheck for federal taxes.');
	const contentSecond = $.__('The IRS has provided a handy %1$swithholding calculator%2$s to help you figure out what to elect.', '<a href="https://apps.irs.gov/app/withholdingcalculator/" target="_blank" rel="noopener noreferrer">', '</a>');
	const content = `${ contentFirst } ${ contentSecond }`;
	return {
		title: $.__('Federal Tax Withholding'),
		content,
		addTop: false
	};
}

/* @endCleanup encore */
