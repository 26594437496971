import { LayoutBox, Section } from '@bamboohr/fabric';
import { ReactNode } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

type Props = {
	currentPage: number;
	pageCount: number;
	loading: boolean;
	routes: {
		path: string;
		component: ReactNode;
	}[];
};

const ContentRouter = ({ currentPage, pageCount, loading, routes }: Props) => {
	const onLastPage = currentPage === pageCount;

	return (
		<HashRouter
			basename="/preview"
			hashType="noslash"
			key={`${currentPage}_${loading}`}
		>
			<LayoutBox flexGrow={1} marginTop={onLastPage ? 0 : '40px'}>
				<Section>
					<Switch>
						{routes.map((route) => (
							<Route path={route.path}>{route.component}</Route>
						))}
					</Switch>
				</Section>
			</LayoutBox>
		</HashRouter>
	);
};

export default ContentRouter;
