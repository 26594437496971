const ERROR_CSS_CLASS = `fab-Label--error`;
const CHECKBOX_ERROR_CSS_CLASS = `fab-CheckboxGroup__legend--error`;
const RADIO_ERROR_CSS_CLASS = `fab-RadioGroup__legend--error`;
const NOTE_SELECTOR = '[class*="fab-FormNote--"]:not(.js-fab-FormNote-ignore)';
const TOGGLE_NOTE_SELECTOR = '.js-fab-FormNote-toggle';
const ERROR_PLACEMENT_SELECTORS = [
	'input', 'textarea', 'ba-select'
];

export const jadeErrorPlacement = (error, $element) => {
	const element = $element[0];
	const isEmail = $element.attr('type') == 'email' || $element.hasClass('validateEmail');
	const hasInlineErrorClass = $element.hasClass('inline-error') || $('label[for="' + $element.attr('id') + '"]').hasClass('inline-error');

	if (
		(
			isEmail ||
			hasInlineErrorClass
		) && error.html() != jQuery.validator.messages.required
	) {

		if ($element.closest('form').hasClass('BhrForms')) {

			const parent = _getLastInputInRow(element).parentNode;

			let note = parent.querySelector(NOTE_SELECTOR);

			if (!note) {
				note = document.createElement('span');
				note.innerText = error.html();

				parent.insertAdjacentElement('beforeend', note);
			}

			note.className = 'fab-FormNote fab-FormNote--error ' + ($element.attr('data-errorclass') || '');
			note.innerHTML = error.html();
		}
	}
};

const getHighlightCheckbox = (element) => {
	return element.classList.contains('js-checkbox-required-single');
}

const toggleCheckboxError = (element, show = false) => {
	if (show) {
		return element.classList.add('fab-Checkbox__input--error');
	}
	return element.classList.remove('fab-Checkbox__input--error');
}

export const jadeHighlight = (element) => {
	switch (element.nodeName) {
		case 'INPUT':
			if (isCheckboxOrRadio(element)) {
				if (getHighlightCheckbox(element)) {
					toggleCheckboxError(element, true);
				}
				break;
			} else if (element.classList.contains('js-rich-text-hidden-input')) {
				const richTextWrapper = element.closest('.BambooRichText');
				if (richTextWrapper) {
					richTextWrapper.classList.add('BambooRichText--error');
				}
			} else if (element.classList.contains('js-validate-ss-required') && $(element).closest('.fieldGroup').hasClass('oneEmailRequired')) {
				$(element).closest('.fieldGroup').addClass('oneEmailRequired--error');
			} else {
				element.classList.add('fab-TextInput--error');
			}
			break;
		case 'TEXTAREA':
			element.classList.add('fab-Textarea--error');
			break;
		case 'BA-SELECT':
			return false;
		default:
			// falls through
	}
	_toggleFormNote(element, true);
	_updateLabel(element);

};

export const jadeUnhighlight = (element) => {
	switch (element.nodeName) {
		case 'INPUT':
			if (isCheckboxOrRadio(element)) {
				if (getHighlightCheckbox(element)) {
					toggleCheckboxError(element, false);
				}
				break;
			} else if (element.classList.contains('js-rich-text-hidden-input')) {
				const richTextWrapper = element.closest('.BambooRichText');
				if (richTextWrapper) {
					richTextWrapper.classList.remove('BambooRichText--error');
				}
			} else if (element.classList.contains('js-validate-ss-required') && $(element).closest('.fieldGroup').hasClass('oneEmailRequired')) {
				$(element).closest('.fieldGroup').removeClass('oneEmailRequired--error');
			} else {
				element.classList.remove('fab-TextInput--error');
			}
			break;
		case 'TEXTAREA':
			element.classList.remove('fab-Textarea--error');
			break;
		// TODO: Update after jade select updates
		default:
			// falls through
	}
	_toggleFormNote(element, false);
	_updateLabel(element, true);

	const parent = element.closest('.fieldRow') || element.closest('.fab-FormRow');
	let note;

	if (parent) {
		note = parent.querySelector(NOTE_SELECTOR);
	}

	if (note) {
		note.parentNode.removeChild(note);
	}

};

const _updateLabel = (input, remove = false) => {
	let label = _findLabel(input);

	if (label) {
		if (remove) {
			return _removeErrorFromLabel(label);
		}

		_addErrorToLabel(label);
	}
};

// Toggle the visibility of a FormNote, so only one FormNote is displayed at a time.
const _toggleFormNote = (element, hide) => {
	const parent = element.closest('.fieldRow') || element.closest('.fab-FormRow');
	let note;

	if (parent) {
		note = parent.querySelector(TOGGLE_NOTE_SELECTOR);
	}

	if (note) {
		if(hide) {
			note.style.display = 'none';
		} else {
			note.style.display = '';
		}
	}
}

const _findLabel = (input) => {
	// For checkboxes and radios
	if (isCheckboxOrRadio(input)) {
		const group = input.closest('.fab-CheckboxGroup') || input.closest('.fab-RadioGroup');
		if (group) {
			const legend = group.querySelector('legend');

			if (legend) {
				return legend;
			}
		}
	}

	// Check for a connected label (preferred)
	if (input.id) {
		const connectedLabel = document.querySelector(`.fab-Label[for="${ input.id }"]`);
		if (connectedLabel) {
			return connectedLabel;
		}
	}

	// Check for a container
	const container = input.closest('.fieldBox') || input.closest('.fab-FormColumn');
	if (container) {
		return container.querySelector('.fab-Label');
	}

	// Default to parentNode query
	return input.parentNode.querySelector('.fab-Label');
};

const _removeErrorFromLabel = (label) => {
	if (label) {
		label.classList.remove(getErrorClass(label));
	}
};

const _addErrorToLabel = (label) => {
	if (label) {
		label.classList.add(getErrorClass(label));
	}
};

const _getLastInputInRow = (element) => {
	const row = element.closest('.fieldRow') || element.closest('.fab-FormRow');
	const inputs = row.querySelectorAll(ERROR_PLACEMENT_SELECTORS.join());
	let lastInput = inputs[inputs.length - 1];

	// Place note outside of inputwrapper if it exists
	if (
		lastInput &&
		lastInput.parentNode.classList.contains('fab-InputWrapper')
	) {
		lastInput = lastInput.parentNode;
	}

	// If lastInput is a masked input, we want to insert at the end of the row (independent of input because warning may be hidden)
	if (lastInput.className.includes('masked')) {
		lastInput = row.children[0]; // get the first child of the row because the parent will be used
	}

	return lastInput;
};

function isCheckboxOrRadio({ type }) {
	return type === 'checkbox' || type === 'radio';
}

function getErrorClass(element) {
	if (element.classList.contains('fab-CheckboxGroup__legend')) {
		return CHECKBOX_ERROR_CSS_CLASS;

	} else if (element.classList.contains('fab-RadioGroup__legend')) {
		return RADIO_ERROR_CSS_CLASS;
	}

	return ERROR_CSS_CLASS;
}
