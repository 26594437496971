import React, {Component, Fragment, createRef} from 'react';
import {consume} from '../../../store/context';
import {classNameFromObject} from '@utils/dom';
import TooltipLegacy from 'Tooltip.mod';
import {
	Avatar,
	BodyText,
	Divider,
	Flex,
	Headline,
	/* @startCleanup encore */
	Icon,
	/* @endCleanup encore */
	InlineMessage,
	InlineMessageStatusType,
	LayoutBox,
	Tooltip,
	TextButton,
} from '@bamboohr/fabric';
import {formatTime} from 'time-tracking/utils';
import {sumAllEarnableHoursInDays} from '../../../utils';
import { Button } from '@fabric/button';

import './styles.styl';
import SummaryBreakdown from '../summary-breakdown.react';
/* @startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */

export class SummaryApproval extends Component {
	state = {
		approveTimesheetPending: false,
	};
	_approvalDisabledTooltip = null;
	_buttonRef = createRef();

	/* @startCleanup encore */
	_createDisabledApprovalTooltip() {
		return TooltipLegacy.create(this._buttonRef.current, {
			html: `
				<div class="ApprovalSummary__tooltip">
					${ $.__('Make sure your changes are saved before you approve.') }
				</div>
			`,
		});
	}
	/* @endCleanup encore */

	_afterApproveTimesheet = () => this.setState({approveTimesheetPending: false});

	_approveTimesheet = () => {
		const {
			focusedTimesheet,
			workflow,
			approveTimesheet,
		} = this.props;

		this.setState({approveTimesheetPending: true});
		approveTimesheet(focusedTimesheet.id, workflow.hoursLastChangedAt)
			.then(this._afterApproveTimesheet)
			.catch(this._afterApproveTimesheet);
	};

	_renderStatus() {
		const {
			userCanApprove,
			status,
		} = this.props.workflow;

		if (status === 'approved') {
			return this._renderApproved();
		} else if (status === 'pending' && !userCanApprove) {
			return this._renderPendingWithoutPermission();
		} else if (status === 'pending' && userCanApprove) {
			return this._renderPendingWithPermission();
		}
	}

	_renderApproved() {
		const {approverName, approvalMoment} = this.props.workflow;
		return ifFeature(
			'encore',
			<InlineMessage
				description={$.__('Approved by %1$s on %2$s.', approverName, approvalMoment.format('MMM D, YYYY'), {
					note: 'e.g. "Approved by Jane Smith on Sep 4, 2017"',
				})}
				icon='circle-check-solid'
				status={InlineMessageStatusType.success}
				title={
					<BodyText color='success-strong' weight='semibold'>
						{$.__('Approved')}
					</BodyText>
				}
			/>,
			<div className='ApprovalSummary__status ApprovalSummary__status--approved'>
				<div className='ApprovalSummary__stamp'>
					<Icon name='fab-approved-stamp-121x39' />
				</div>
				{$.__('Approved by %1$s on %2$s.', approverName, approvalMoment.format('MMM D, YYYY'), {
					note: 'e.g. "Approved by Jane Smith on Sep 4, 2017"',
				})}
			</div>
		);
	}

	_renderPendingWithoutPermission() {
		const {approverName} = this.props.workflow;
		return ifFeature(
			'encore',
			<InlineMessage
				description={approverName
					? $.__('Waiting for approval from %1$s', approverName, { note: 'e.g. "Waiting for approval from John Henry' })
					: $.__('Waiting for approval')}
				icon='hourglass-regular'
				title={$.__('Pending')}
			/>,
			<div className='ApprovalSummary__status ApprovalSummary__status--waiting baseBgColor'>
				{approverName
					? $.__('Waiting for approval from %1$s', approverName, { note: 'e.g. "Waiting for approval from John Henry' })
					: $.__('Waiting for approval')}
			</div>
		);
	}

	_renderPendingWithPermission() {
		const {
			workflow: {dueMoment},
			getChangedEntries,
		} = this.props;

		const disableApproval = Boolean(getChangedEntries().length);
		const button = (
			<Button color="primary" onClick={this._approveTimesheet} size="medium" startIcon="circle-check-regular" type="button">
				{$.__('Approve')}
			</Button>
		);

		/* @startCleanup encore */
		if (ifFeature('encore', false, true)) {
			if (disableApproval && !this._approvalDisabledTooltip) {
				this._approvalDisabledTooltip = this._createDisabledApprovalTooltip();
			} else if (!disableApproval && this._approvalDisabledTooltip) {
				this._approvalDisabledTooltip.destroy();
				this._approvalDisabledTooltip = null;
			}
		}
		/* @endCleanup encore */

		return ifFeature(
			'encore',
			<>
				<Flex alignSelf='stretch' flexDirection='column'>
					{disableApproval && !this._approvalDisabledTooltip ? (
						<Tooltip content={$.__('Make sure your changes are saved before you approve.')}>{button}</Tooltip>
					) : (
						button
					)}
				</Flex>
				<BodyText color='neutral-weak'>
					{$.__('Must approve by %1$s', dueMoment.format('MMM D'), { note: 'e.g. "Must approve by May 17"' })}
				</BodyText>
			</>,
			<div className='ApprovalSummary__status ApprovalSummary__status--showButton baseBgColor'>
				<div className='ApprovalSummary__buttonWrapper' ref={this._buttonRef}>
					<Button
						clickAction={this._approveTimesheet}
						iconBefore="fab-check-circle-16x16"
						outline={true}
						size="large"
						text={$.__('Approve')}
						width={100}
					/>
				</div>
				{$.__('Must approve by %1$s', dueMoment.format('MMM D'), { note: 'e.g. "Must approve by May 17"' })}
			</div>
		);
	}

	render() {
		const {
			employeeId,
			employeeName,
			employeePhotoUrl,
			focusedTimesheet,
			payPeriodTitleText,
			payPeriodRangeText,
			workflow,
			getFocusedDays,
			summaryTotals,
			isViewOnly,
		} = this.props;

		const {
			periodOvertime,
			periodTimeOff,
			periodHoliday,
			shiftDifferentials
		} = summaryTotals;

		const timesheetIsApproved = (workflow.status === 'approved');
		const focusedDays = getFocusedDays();

		return ifFeature(
			'encore',
			<Flex alignItems='center' flexDirection='column' gap={1.5} >
				<LayoutBox>
					<Avatar alt={$.__('employee avatar')} src={employeePhotoUrl} />
				</LayoutBox>
				<BodyText size='large' weight='bold'>
					{isViewOnly ? employeeName : payPeriodTitleText}
				</BodyText>
				<Headline color='primary' size='medium'>
					{formatTime(sumAllEarnableHoursInDays(focusedDays))}
				</Headline>
				<BodyText color='neutral-strong' size='small'>
					{payPeriodRangeText}
				</BodyText>
				{(!!periodOvertime.length || !!periodTimeOff || !!periodHoliday || !!shiftDifferentials.length) ? <>
					<LayoutBox width='100%'>
						<Divider color='neutral-extra-weak' />
					</LayoutBox>
					<SummaryBreakdown {...{ periodOvertime, periodTimeOff, periodHoliday, shiftDifferentials }} />
				</> : null}
				{!isViewOnly && this._renderStatus()}
				{isViewOnly && (
					<TextButton
						component="a"
						endIcon="chevrons-right-solid"
						href={`/employees/timesheet?id=${employeeId}&et_id=${focusedTimesheet.id}`}
						text={$.__('Go to timesheet')} />
				)}
			</Flex>,
			<Fragment>
				<img alt={$.__('employee avatar')} className='TimesheetSummary__photo' src={employeePhotoUrl} />
				<div className='TimesheetSummary__title'>{isViewOnly ? employeeName : payPeriodTitleText}</div>
				<div className='TimesheetSummary__text'>{payPeriodRangeText}</div>
				<div
					className={classNameFromObject({
						ApprovalSummary: true,
						'ApprovalSummary--approved': timesheetIsApproved,
						'ApprovalSummary--viewOnly': isViewOnly,
					})}
				>
					<div
						className={classNameFromObject({
							ApprovalSummary__payPeriodTotal: true,
							'ApprovalSummary__payPeriodTotal--approved': timesheetIsApproved,
						})}
					>
						{formatTime(sumAllEarnableHoursInDays(focusedDays))}
					</div>
					{SummaryBreakdown({ periodOvertime, periodTimeOff, periodHoliday, shiftDifferentials })}
					{!isViewOnly && this._renderStatus()}
				</div>
				{isViewOnly && (
					<a className='fab-Link fab-Link--muted' href={`/employees/timesheet?id=${employeeId}&et_id=${focusedTimesheet.id}`}>
						{$.__('Go to timesheet')} &raquo;
					</a>
				)}
			</Fragment>
		);
	}
}


export default consume([
	// Properties
	'focusedTimesheet',
	'workflow',
	'summaryTotals',
	'isViewOnly',
	// Actions
	'approveTimesheet',
	// Queries
	'getFocusedDays',
	'getChangedEntries',
], SummaryApproval);
